
import {defineComponent, ref} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import UserService from "@/core/services/UserService";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {GetSpaceId, WorkspaceSelect} from "@/core/composite/composite";

export default defineComponent({
  name: "InviteUserForm",
  components: {BaseSelect, FormErrorMessage, BaseForm, Field},
  emits: ['invited'],
  setup() {
    const submitting = ref(false);
    const spaceId = GetSpaceId().spaceId;
    const model = ref({email: '', spaceId: spaceId.value});
    const validate = ref({});
    return {
      validate,
      model,
      submitting,
      ...WorkspaceSelect(),
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      UserService.invite(this.model).then(() => {
        this.$emit('invited')
      }).finally(() => {
        this.model = {email: '', spaceId: ''}
        this.submitting = false;
      })
    }
  }
})
